import axios from "axios";
import Vue from "vue";
import { Message } from "element-ui";
import router from "../router/index.js";
import { startLoading, endLoading,sleep } from "@/utils/function";

// if (process.env.NODE_ENV === "development") {
//     axios.defaults.baseURL = "/";
// } else {
//     axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
// }
// axios.defaults.baseURL = "https://www.mools.net/lims/api/laravel/public";
// 5000s超时
axios.defaults.timeout = 5000 * 1000;
// 解决后端获取不到session问题
axios.defaults.withCredentials = true;

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// 上线打包修改修改此处，否则访问链接不对  需要解开注释
var ENV_MODE = process.env.VUE_APP_ENV_MODE;
if(ENV_MODE == 'production')
{
    axios.defaults.baseURL = "https://app.mools.net/backend/public";
}

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// post头
axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded;charset=UTF-8";

// 请求拦截
axios.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.error(error);
    }
);
// 响应拦截
axios.interceptors.response.use(
    response => {
        //本地存储loading为true时展示loading效果
        let loading = window.sessionStorage.getItem('loading');

        if (response.status === 200) 
        {
            if (response.data.code > 0) 
            {
                // Message.success(response.data.msg);
                if(response.data.msg)
                {
                  Vue.prototype.$Tips({
                    // 消息提示内容
                    message:response.data.msg ,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                    button:[],
                    // 弹窗消失时间（button 与 display不同时存在，如果同时存在，以button为主）
                    displayTime:1500
                  })
                }
                return Promise.resolve(response);
            } 
            else if (response.data.code == -200) 
            {
                // Message.warning(response.data.msg || "操作失败");
                Vue.prototype.$Tips({
                    // 消息提示内容
                    message:response.data.msg || "操作失败",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    // 弹窗消失时间（button 与 display不同时存在，如果同时存在，以button为主）
                    displayTime:1500
                  })
                // 跳登录页
                router.push("/login");
            } 
            else if (response.data.code == -100) 
            {
                // Message.error("网络超时！请稍后再试！");
                Vue.prototype.$Tips({
                    // 消息提示内容
                    message:'网络超时',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                    button:[
                      {
                        type:1,
                        text:'知道了'
                      }
                    ],
                  })
            }
            // else if (response.data.code == -3) 
            // {
            //   Vue.prototype.$Tips({
            //       // 消息提示内容
            //       message:response.data.msg,
            //       // 消息提示类型（success-成功,warning-警告/失败）
            //       messageType:'warning',
            //       // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
            //       button:[
            //         {
            //           type:1,
            //           text:'知道了'
            //         }
            //       ],
            //     })
            // }
            // else 
            // {
            //     if(response.data.msg)
            //     {
            //         // Message.error(response.data.msg);
            //         Vue.prototype.$Tips({
            //             // 消息提示内容
            //             message:response.data.msg,
            //             // 消息提示类型（success-成功,warning-警告/失败）
            //             messageType:'warning',
            //             // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
            //             button:[
            //               {
            //                 type:1,
            //                 text:'知道了'
            //               }
            //             ],
            //           })
            //     }
                return Promise.resolve(response);
            // }
        } 
        else 
        {
            // 这里写sleep之后需要去做的事情
            // endLoading(startLoading());
            Vue.prototype.$Loading.hide()
            // Message.error("网络超时！请稍后再试！");
            Vue.prototype.$Tips({
                // 消息提示内容
                message:'网络超时',
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType:'warning',
                // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                button:[
                  {
                    type:1,
                    text:'知道了'
                  }
                ],
              })
            return Promise.reject(response);
        }
    },
    error => {
        // loading显示时间，至少显示1秒  解决闪屏的问题
        // sleep(1000).then(() => {
            // 这里写sleep之后需要去做的事情
            // endLoading(startLoading());
            Vue.prototype.$Loading.hide()
        // });
        // Message.error("网络超时！请稍后再试！");
        Vue.prototype.$Tips({
            // 消息提示内容
            message:'网络超时',
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType:'warning',
            // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
            button:[
              {
                type:1,
                text:'知道了'
              }
            ],
          })
        return Promise.reject(error.response);
    }
);
